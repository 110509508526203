import { Box, FormControlLabel, Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type FeatureToggleProps = {
  value: boolean
  handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
}

export function FeatureToggle({ value, handleChange }: FeatureToggleProps) {
  const { t } = useTranslation()
  return (
    <Box sx={{ m: 2, width: '25ch' }}>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={t('Enable V2')}
      />
    </Box>
  )
}
